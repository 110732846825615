<template>
  <div v-if="show_form">
    <Form
      v-if="formInReset === false"
      class="column justify-between full-height q-mt-lg"
      :initial-values="Object.assign({}, initialValues)"
      :validation-schema="schema"
      @submit="onSubmit"
    >
      <div class="row q-mb-md" v-show="typePerson.length === 2">
        <q-radio
          @update:model-value="onChangedTypePerson"
          v-model="personType"
          color="dark"
          val="pf"
          :label="$t('login.signUp.physical')"
        />
        <q-radio
          @update:model-value="onChangedTypePerson"
          v-model="personType"
          color="dark"
          val="pj"
          :label="$t('login.signUp.juridic')"
        />
      </div>
      <Field
        v-for="(structure, key) in structureForm"
        :key="key"
        :name="structure.name"
        v-slot="{ errorMessage, value, field }"
      >
        <q-input
          filled
          bottom-slots
          color="dark"
          :debounce="1000"
          v-bind="field"
          :model-value="value"
          class="q-mb-sm q-mt-sm"
          :type="structure.type"
          :mask="structure.mask"
          :label="structure.label"
          :error="!!errorMessage"
          :error-message="errorMessage"
          :counter="!!structure.maxlength"
          :maxlength="structure.maxlength"
          :stack-label="structure['stack-label']"
          @keyup="keepValue(structure.name, $event)"
          :prefix="structure.name.includes('instagram') ? '@' : undefined"
          @change="setCpfSignup(structure.name, $event)"
        />
        <div
          class="bg-blue-1 q-pa-sm q-mb-sm default-rounded"
          v-if="fieldHasMessage(structure.name)"
          v-html="$t(`login.signUp.${structure.name}`)"
        />
        <q-item
          class="q-ma-none q-pa-none"
          v-if="canShowLoginRoute(field.name, errorMessage)"
          :to="{ name: 'login', query: { email: value } }"
        >
          <q-item-label class="q-mt-xs text-weight-bold text-blue-11">
            <small class="cursor-pointer">
              {{ $t("login.signUp.would_like_to_access") }}
            </small>
          </q-item-label>
        </q-item>
      </Field>

      <!-- CAMPO PARA CARTERINHA/CÓDIGO DE PAREIROS -->
      <Field
        v-if="sign_up_skeleton.id_validation"
        :name="sign_up_skeleton.id_validation.field"
        v-slot="{ value, field, errorMessage }"
      >
        <q-input
          color="dark"
          class="q-mb-sm q-mt-sm"
          bottom-slots
          filled
          :counter="true"
          :maxlength="11"
          :debounce="1000"
          :disable="!abbleExternalId()"
          :label="sign_up_skeleton.id_validation.label"
          :mask="sign_up_skeleton.id_validation.mask"
          :placeholder="sign_up_skeleton.id_validation.placeHolder"
          v-bind="field"
          :model-value="value"
          :error-message="errorMessage"
          :error="!!errorMessage"
        >
          <template v-slot:append>
            <q-btn
              flat
              icon="help"
              v-if="dataHelper.helper"
              v-model="dataHelper.helper"
              class="q-ma-none q-pa-none "
              color="red"
              @click="help = true"
            />
          </template>
        </q-input>
      </Field>

      <q-btn
        outline
        color="white"
        type="submit"
        :loading="in_request"
        :disable="in_request"
        :label="$t('login.signUp.next')"
        class="width-100 bg-grey-10 q-mt-lg q-pa-sm q-pr-lg q-pl-lg"
      />
      <!------>
    </Form>

    <!-- External ID help -->
    <q-dialog v-model="help">
      <q-card>
        <q-bar class="bg-dark text-white">
          <div>{{ sign_up_skeleton.id_validation.label }}</div>
        </q-bar>
        <q-card-section
          class="q-pt-none bg-grey-4 q-pa-md q-ma-md default-rounded text-justify"
        >
          <span v-html="dataHelper.helper" />
        </q-card-section>
        <q-card-actions align="right" class="q-pt-none">
          <q-btn
            flat
            :label="$t('global.close')"
            color="primary"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import {
  labels,
  build,
  getStructure,
  getMappedFields,
} from "@/shared/form-presets";
import { getInitialValues } from "../v2/components/setup";
import { dataHelper } from "@/shared/helpers/dataHelper";
import { useRoute, useRouter } from "vue-router";
import { signUpStepOneConfig } from "./setup";
import { ref, nextTick, onMounted } from "vue";
import { baseURL } from "@/shared/http/api";
import { Field, Form } from "vee-validate";
import axios from "axios";

export default {
  name: "formStepOne",

  components: {
    Field,
    Form,
  },

  props: {
    typePerson: {
      type: Array,
      required: true,
    },
    sign_up_skeleton: {
      required: false,
      type: Object,
    },
  },

  emits: ["finished", "changedPersonType"],

  setup(props, { emit }) {
    const formInReset = ref(false),
      in_request = ref(false),
      show_form = ref(false),
      schema = ref(null),
      router = useRouter(),
      route = useRoute();

    const localInitialValues = localStorage.getItem("signUpStepOne");

    const structureForm = ref(null),
      isSocialNetworkRequired = ref("0"),
      personType = ref(props.typePerson[0].toLowerCase()),
      initialValues = ref(
        getInitialValues(
          route.query.name,
          localInitialValues ? JSON.parse(localInitialValues) : null
        )
      );

    const buildFormSetup = (type) => {
      let fieldsDefinitions = signUpStepOneConfig(
        type,
        isSocialNetworkRequired.value
      );

      fieldsDefinitions = [
        ...fieldsDefinitions,
        ...(props.sign_up_skeleton.id_validation ? ["idExterno"] : []),
      ];

      structureForm.value = getStructure(fieldsDefinitions);

      if (type === "pj")
        structureForm.value.name_default_contact.label =
          labels.name_default_contact;
      else structureForm.value.complete_name.label = labels.complete_name;

      schema.value = build(getMappedFields(fieldsDefinitions));
      nextTick(() => (formInReset.value = false));
    };

    const onChangedTypePerson = (newValue) => {
      formInReset.value = true;

      localStorage.removeItem("signUpStepOne");

      formInReset.value = false;

      emit("changedPersonType", newValue);
      nextTick(() => buildFormSetup(newValue));
    };

    const keepValue = (name, event) => {
      const newInitialValues = Object.assign({}, initialValues.value);
      newInitialValues[name] = event.target.value;
      initialValues.value = newInitialValues;
    };

    const verifySocialLogin = () => {
      axios
        .get(
          `${baseURL}/api/v1/parameters/p/${route.params.slug}/is_social_network_required`
        )
        .then(({ data }) => {
          show_form.value = true;
          isSocialNetworkRequired.value = data.value;
          buildFormSetup(personType.value);
        })
        .catch(() => router.push({ name: "login" }));
    };

    const onSubmit = (values) => {
      if (isSocialNetworkRequired.value == 1) {
        values.instagram = values.instagram_req;
        delete values.instagram_req;
      }

      in_request.value = true;
      let data = {
        ...values,
        type_person: personType.value,
        facebook_id: route.query.id || null,
      };

      localStorage.setItem("signUpStepOne", JSON.stringify(data));

      emit("finished", { data, goTo: 2 });
    };

    onMounted(() => {
      verifySocialLogin();
      localStorage.removeItem("signUpCPF");

      // rule to not abble external id field without a valid cpf
      localStorage.setItem(
        "validCPFToSignup",
        JSON.stringify({ value: false })
      );

      const stepOne = localStorage.getItem("signUpStepOne");

      if (stepOne) {
        const { type_person } = JSON.parse(stepOne);
        personType.value = type_person;
      }
    });

    const abbleExternalId = () => {
      const validCPFToSignup = localStorage.getItem("validCPFToSignup");
      if (validCPFToSignup) {
        const { value } = JSON.parse(validCPFToSignup);
        return value;
      }
      return false;
    };

    return {
      schema,
      show_form,
      in_request,
      personType,
      formInReset,
      initialValues,
      structureForm,
      onSubmit,
      keepValue,
      abbleExternalId,
      dataHelper,
      help: ref(false),
      alert: ref(false),
      onChangedTypePerson,
      canShowLoginRoute(fieldName, msgError) {
        return fieldName === "email" && msgError && msgError.includes("base");
      },
      fieldHasMessage: (fieldName) => {
        const fields = ["document_pj", "instagram", "instagram_req"];
        return fields.includes(fieldName);
      },

      /*-----------------*/
      setCpfSignup(field, value) {
        if (field === "document_cpf_contact") {
          if (!value) localStorage.removeItem("signUpCPF");
          else
            localStorage.setItem(
              "signUpCPF",
              JSON.stringify({
                document_cpf_contact: value,
              })
            );
        }
      },
    };
  },
};
</script>
