<template>
  <q-ajax-bar
    ref="bar"
    position="top"
    color="blue-11"
    size="10px"
    skip-hijack
  />

  <q-intersection
    transition="slide-up"
    class="full-width full-height overflow-hidden q-ma-none"
  >
    <q-card-section v-if="inRequest">
      <register-skeleton class="full-width" />
    </q-card-section>
    <q-card-section v-else>
      <completed-register
        v-if="blacklisted_register"
        :layout="blacklistedData"
      />
      <completed-register
        v-else-if="show_completed_register"
        :layout="sign_up_skeleton.messages.step3"
      />
      <unsuccessfully-register v-else-if="unsuccessfully_register" />
      <register
        v-else
        @submitted="createPartner"
        :layout="sign_up_skeleton.messages.step1"
        :type-person="sign_up_skeleton.partner_types"
      />
    </q-card-section>
  </q-intersection>
</template>

<script setup>
import UnsuccessfullyRegister from "@/modules/auth/pages/sign-up/components/unsuccessfullyRegister";
import CompletedRegister from "@/modules/auth/pages/sign-up/components/completedRegister";
import RegisterSkeleton from "@/modules/auth/pages/sign-up/components/registerSkeleton";
import Register from "@/modules/auth/pages/sign-up/components/register";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { notifyError } from "@/shared/helpers/notify";
import { useGtm } from "@/shared/helpers/gtmHelper";
import { useRouter, useRoute } from "vue-router";
import { useReCaptcha } from "vue-recaptcha-v3";
import { baseURL } from "@/shared/http/api";
import { ref, onMounted } from "vue";
import Cookies from "js-cookie";
import axios from "axios";

const { useActions, useGetters } = createNamespacedHelpers("auth");

const bar = ref(null),
  router = useRouter(),
  route = useRoute(),
  inRequest = ref(true),
  blacklistedData = ref({}),
  blacklisted_register = ref(false),
  show_completed_register = ref(false),
  unsuccessfully_register = ref(false);

const isLoginToUploadDocs = ref(0);

const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

const { fetchSignUpSkeleton, storeSignUp, signInAction, validateSignUp } =
    useActions([
      "fetchSignUpSkeleton",
      "signInAction",
      "storeSignUp",
      "validateSignUp",
    ]),
  { sign_up_skeleton, approved_status } = useGetters({
    sign_up_skeleton: "getSignUpSkeleton",
    approved_status: "getSignUpCreateApprovedStatus",
  });

bar.value = null;

const createPartner = (values) => {
  inRequest.value = true;
  validateSignUp(values).then((res) => {
    if (res.status == "error") {
      inRequest.value = false;
      blacklistedData.value = JSON.parse(res.message);
      if (res.message == null) router.push({ name: "login" });
      blacklisted_register.value = true;
    } else {
      bar.value.start();
      storeSignUp(values)
        .then(async () => {
          if (approved_status.value == 0) {
            inRequest.value = false;
            show_completed_register.value = true;
          } else {
            if (
              (isLoginToUploadDocs.value == 0 && approved_status.value == 1) ||
              (approved_status.value == "null" &&
                isLoginToUploadDocs.value == 1)
            ) {
              inRequest.value = true;

              await recaptchaLoaded();
              let token = await executeRecaptcha("login");

              signInAction({
                email: values.email,
                senha: values.password,
                slug: values.slug_parent,
                recaptcha_response: { token }.token,
              })
                .then(() => {
                  const searchParams = Cookies.get("route-param-search");
                  if (searchParams) window.location.href = searchParams;
                  else router.push({ name: "dashboard" });
                })
                .catch(() => router.push({ name: "login" }));
            } else {
              inRequest.value = false;
              show_completed_register.value = true;
            }
          }
        })
        .catch(() => {
          notifyError("notify.unprocessed_error_on_created");
          inRequest.value = false;
          unsuccessfully_register.value = true;
        })
        .finally(() => bar.value.stop());
    }
  });
};

const verifyLoginToUploadDocs = (slug) => {
  fetchParam(slug, "signup_redirect_upload_doc")
    .then(({ data }) => (isLoginToUploadDocs.value = data.value))
    .catch(() => (isLoginToUploadDocs.value = !isLoginToUploadDocs.value));
};

const fetchParam = (slug, param) => {
  return axios.get(`${baseURL}/api/v1/parameters/p/${slug}/${param}`);
};

onMounted(() => {
  if (route.params.slug == "flipnet") router.push({ name: "login" });

  bar.value.start();
  fetchSignUpSkeleton().then(() => {
    inRequest.value = false;
    if (bar.value) bar.value.stop();
  });

  verifyLoginToUploadDocs(route.params.slug);

  fetchParam(route.params.slug, "inject_gtm_signup")
    .then(({ data }) => data.value && useGtm(data.value))
    .catch(() => console.log("error: inject_gtm_signup"));
});
</script>
